import { format } from 'date-fns'
import React from 'react'
import { Element, Form, Icon } from 'react-bulma-components'
import { useController } from 'react-hook-form'

const FormField = ({ label, children, bodyFieldProps = {}, horizontal = true }) =>
  <Form.Field horizontal={ horizontal }>
    { label && horizontal &&
      <Form.Field.Label>
        <Form.Label>
          { label }
        </Form.Label>
      </Form.Field.Label>
    }
    { label && !horizontal &&
      <Form.Label>
        { label }
      </Form.Label>
    }
    <Form.Field.Body>
      <Form.Field {...bodyFieldProps}>
        { children }
      </Form.Field>
    </Form.Field.Body>
  </Form.Field>

const Control = ({
  icon,
  error,
  help,
  controlProps = {},
  children
}) =>
  <>
    <Form.Control {...controlProps}>
      {
        icon &&
          <Icon align='left'>
            { icon }
          </Icon>
      }
      { children }
      {error && <Element textColor='danger'>{error}</Element>}
    </Form.Control>
    {
      help &&
        <Form.Help>
          { help }
        </Form.Help>
    }
  </>

FormField.Control = Control


//Input

FormField.Input = (props) => {
  const inputProps = props.inputProps || {}
  return (
    <FormField.Control {...props}>
      <Form.Input
        placeholder={ props.placeholder }
        type={props.type}
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
        {...inputProps}
      />
    </FormField.Control>
  )
}

export const HookFormWrapper = (props) => {
  const { field, fieldState, formState } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
    defaultValue: props.defaultValue
  })

  const Component = FormField[props.component || 'Input']
  const componentProps = React.useMemo(() => {
    const componentProps = { ...props }
    delete componentProps.control
    delete componentProps.rules
    delete componentProps.defaultValue
    return componentProps
  }, [props])

  return <Component
    inputProps={ { ...field, disabled: props.disabled, style: props.style, defaultValue: props.defaultValue } }
    { ...componentProps }
    error={formState.isSubmitted && fieldState.invalid && fieldState.error && fieldState.error.message}
  />
}


//Checkbox

FormField.Checkbox = (props) => {
  const inputProps = props.inputProps || {}
  return (
    <FormField.Control {...props}>
      <Element
        renderAs='label'
        className='checkbox'
      >
        <Element
          renderAs='input'
          type='checkbox'
          { ...inputProps }
          defaultChecked={inputProps.value}
          mr={1}
        />
        {props.label}
      </Element>
    </FormField.Control>
  )
}

//Select
FormField.Select = (props) => {
  const inputProps = props.inputProps || {}
  return (
    <FormField.Control {...props}>
      <Element
        renderAs='label'
        className='select'
      >
        <Element
          renderAs='select'
          { ...inputProps }
        >
          {
            props.options && props.options.map(option =>
              <option key={ option.value } value={ option.value }>{ option.label }</option>
            )
          }
          {
            props.placeholder &&
              <option value={ '__none' }>- { props.placeholder } -</option>
          }
        </Element>
      </Element>
    </FormField.Control>
  )
}

//textarea
FormField.Textarea = (props) => {
  const inputProps = props.inputProps || {}
  return (
    <FormField.Control {...props}>
      <Element
        renderAs='textarea'
        p={3}
        { ...inputProps }
      />
    </FormField.Control>
  )

}

FormField.Radios = (props) => {
  const inputProps = props.inputProps || {}
  return (
    <FormField.Control {...props}>
      <Element
        renderAs='label'
        className='radio'
      >
        {
          props.options && props.options.map(option => (
            <Element
              key={option.value}
              mb={1}
            >
              <Form.Radio
                name='story-radio-name'
                {...inputProps}
                value={ option.value }
                defaultChecked={option.value === inputProps.defaultValue}
              >
                {option.label}
              </Form.Radio>
            </Element>
          ))
        }
      </Element>
    </FormField.Control>
  )
}


FormField.Date = (props) => {
  const inputProps = props.inputProps || {}

  return (
    <FormField.Control {...props}>
      <Form.Input
        placeholder={ props.placeholder }
        type={'date'}
        {...inputProps}
        value={ inputProps.value && format(new Date(inputProps.value), 'yyyy-MM-dd') }
        onChange={ (e) => props.inputProps.onChange(e.target.valueAsDate)}
      />
    </FormField.Control>
  )
}

export default FormField